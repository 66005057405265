<template>
  <div :class="additionalClass">
    <div v-if="title" class="title is-2" :class="additionalTitleClass">
      {{ title }}
    </div>
    <div v-if="subTitle" class="subtitle is-4" :class="additionalSubTitleClass">
      {{ subTitle }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    additionalClass: {
      type: String,
      default: "has-margin-bottom-x2"
    },
    additionalTitleClass: {
      type: String,
      default: ""
    },
    additionalSubTitleClass: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    }
  }
};
</script>
